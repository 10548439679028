@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Akira";
  src: url('../font/Akira Expanded Demo.otf');
}

@layer components {
  .service-icon {
    @apply fill-accent;
    height: 46px;
  }

  .social-icon {
    @apply fill-accent;
    height: 26px;
  }
}
